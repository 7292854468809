<template></template>

<script>
export default {
  name: "auth-bind",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeWindow() {
      var userAgent = navigator.userAgent;
      if (
        userAgent.indexOf("Firefox") != -1 ||
        userAgent.indexOf("Chrome") != -1
      ) {
        window.location.href = "about:blank";
        window.close();
      } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    },
  },
  computed: {
    params() {
      return this.$router.history.current.query;
    },
  },
  created() {
    const that = this;
    if (this.params) {
      setTimeout(() => {
        var parentWin = window.opener;
        if (parentWin) { 
          parentWin.authShow(that.params);
        }
        this.closeWindow();
      }, 200);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
</style>